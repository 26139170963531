import React from "react";
import Seo from "../components/Seo/Seo";
import { Section, Text, Button, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import hero from './../assets/image/jpeg/diversions.jpg';
import nextSteps from './../assets/image/jpeg/wayleaves-landlords-next-steps.jpg';
import tenantsBackground from "./../assets/image/jpeg/tenants-background.jpg";
import diversions from './../assets/image/png/diversions_circle_color.png';
import diversions_transaprent from './../assets/image/png/diversions_circle_transparent.png';
import { Container, Accordion, Row, Col } from "react-bootstrap";
import tenants1 from './../assets/image/svg/value-of-property.svg';
import tenants2 from './../assets/image/svg/tennants-are-happier.svg';
import tenants3 from './../assets/image/svg/attractive-propert.svg';
import bottomMotive from './../assets/image/png/bottom-motive-white.png';
import fullWidthTenantsBackground from './../assets/image/jpeg/full-width-tenants.jpg';
import caretUp from './../assets/image/png/caret-up.png';
import caretDown from './../assets/image/png/caret-down.png';
import checkmark from './../assets/image/png/checkmark.png';

const Diversions = (props) => {

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 120px;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 120px;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 120px;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 0 100px 0;
        p {
            color: #000;
            text-align: left;
            margin-bottom: 10px;
        }
        .we-can-list {
            font-size: 28px;
            color: #000;
            img {
                margin-top: 10px;
            }
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }

        @media (max-width: 991px) { 
            padding-top: 0;
            padding-bottom: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: calc((100vw - 1125px) / 2);
            padding-right: 0;
        }
    `;

    const Box = styled.div`
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        @media (max-width: 991px) {
            padding: 25px;
            .first-img {
                margin-bottom: 25px;
            }
            .second-img {
                margin-top: 25px;
            }
        }
    `;

    const NarrowBox = styled(Box)`
        max-width: 600px;
        display: block;
        margin: 100px auto;
        text-align: center;
        @media (max-width: 991px) {
            margin: 0 auto;
        }
    `;

    const StyledImage = styled.img`
        border-radius: 20px;
        width: 100%;
    `;

    const StyledSubtitle = styled.h2`
        color: #000;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1;
        letter-spacing: -0.56px;
    `;

    const StyledSection = styled(Section)`
        
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        h2, p, a {
            color: #fff !important;
        }
        p {
            font-size: 28px; 
        }
        .container {
            position: relative;
        }
    `;

    const AbsoluteImage = styled.img`
        position: absolute;
        bottom: 0;
        left: 0;
    `;

    const StyledAccordion = styled(Accordion)`
        .accordion-item {
            border: none;
            border-bottom: 1px solid #ce178a !important;
        }
        .accordion-button {
            
        }
        .accordion-header .accordion-button {
            text-transform: unset;
            font-size: 1.25rem;
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            color: #000;
        }
        .accordion-body {
            padding: 0 0 20px 0;
        }
        .accordion-button::after {
            background-image: url(${caretDown});
        }
        .accordion-item:last-of-type,
        .accordion-item:last-of-type .accordion-button {
            border-radius: 0 !important;
        }
    `;

    const BgSection = styled(Section)`
        background: url(${tenantsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;
    
    const FullWidthSection = styled(Section)`
        background: url(${fullWidthTenantsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;

    const HeroImg = styled.img`
        filter: brightness(0.8);
    `;

    const BulletsList = styled.ul`
      list-style: disc;
      li::marker {
        color: rgb(206, 3, 144);
      }
    `;

    const DotDecorator = styled.span`
        &:after {
            content: "•";
            display: block;
            position: relative;
            top: 5px;
            color: #ce0390;
        }
    `;

    return (
        <div>
            <Seo title='Netomnia - Wayleaves for landlords' description='Netomnia - Wayleaves for landlords'/>
            <div className="hero-wayleaves bg-white">
                <HeroImg src={hero} className="w-100 mt-10" />
                <AbsoluteTitle>
                    Netomnia<br />
                    network<br />
                    diversions<br />
                    & alterations
                </AbsoluteTitle>
            </div>
            <PageWrapper>
                <Row className="justify-content-center text-left">
                    <Col xl="7">
                        <NarrowWrapper className="pt-5">
                            <Text>
                                If you find that our equipment is preventing your
                                construction or build project, there are several
                                actions we can take to help.
                            </Text>
                            <Title variant="news" className="mt-5 mb-4">We can:</Title>
                            <ul className="we-can-list">
                                <li className="flex mb-3"><img src={checkmark} width={30} /><Text>Relocate, remove or adjust our equipment to facilitate your construction process.</Text></li>
                                <li className="flex mb-3"><img src={checkmark} width={30} /><Text>Ensure that our network is properly managed during any demolition work.</Text></li>
                                <li className="flex mb-3"><img src={checkmark} width={30} />
                                    <Text>
                                        Support with any surveys and audits of our equipment
                                        affected by your project. We can safeguard them during
                                        the construction process and reinstate once your work is
                                        completed.    
                                    </Text>
                                </li>
                                <li className="flex mb-3"><img src={checkmark} width={30} />
                                    <Text>
                                        Aid in relocation, adjustment or removal of our equipment
                                        to facilitate roadworks, bridge constructions and local
                                        development efforts.
                                    </Text>
                                </li>
                            </ul>
                        </NarrowWrapper>
                    </Col>
                    <Col xl="1"></Col>
                    <Col xl="4" className="flex-start">
                        <img src={diversions} className="w-100 first-img"/>
                    </Col>
                </Row>
                <Container>
                    <hr className="mb-5"/>
                    <Row className="pt-5 mb-5">
                        <Col xl="6">
                            <Title variant="news">
                                Before any works can
                                commence, we do need
                                to have the following
                                information in order to
                                make the process as
                                smooth as possible:
                            </Title>
                        </Col>
                        <Col xl="6">
                            <ul className="we-can-list">
                                <li className="flex mb-3"><DotDecorator/><Text className="pl-3">Agreement on a specification of works and estimation of cost.</Text></li>
                                <li className="flex mb-3"><DotDecorator/><Text className="pl-3">Final Design detailing the required works to be carried out</Text></li>
                                <li className="flex mb-3"><DotDecorator/><Text className="pl-3">Overview of project timelines, including the on-site construction schedule and any constraints regarding access to the site.</Text></li>
                                <li className="flex mb-3"><DotDecorator/><Text className="pl-3">Contact information of the Project Manager or site agent overseeing the works.</Text></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <GradientSection>
                    <AbsoluteImage src={diversions_transaprent} />
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <Text className="w-80 mb-5 centered">
                                    Without the above information, we will be
                                    unable to provide an estimate of costs.
                                    Depending on the scale of the alteration to
                                    our network, there may be costs associated
                                    with the preparation of the estimate.
                                </Text>
                                <a href="mailto:diversionary-work@netomnia.com">
                                    <Button
                                        variant={"white"}
                                        size="sm"
                                        color={'rgb(252,58,76)'}
                                    >
                                        GET IN TOUCH
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </div>
    )
}

export default Diversions;
